var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widget widget_services inline-links"},[_c('h2',{staticClass:"widget-title"},[_vm._v(" "+_vm._s(_vm.$t('Nos services'))+" ")]),_vm._v(" "),_c('ul',_vm._l((_vm.data),function(value,index){return _c('li',{key:index},[_c('h4',[_c('span',{staticClass:"angle-right-regular"},[_vm._v(">>")]),_vm._v(" "),_c('nuxt-link',{attrs:{"to":_vm.getLocalizedRoute({
                    name: 'news-category-slug'
                    , params: {
                        category: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.category.slug, 'tag')
                        , slug: _vm.$tradLinkSlug(_vm.$store.state.i18n.currentLocale, value.slug, 'article')
                    }
                })}},[(value.alternativeHeadline)?_c('span',[_vm._v("\n                        "+_vm._s(_vm.$t(value.alternativeHeadline))+"\n                    ")]):_c('span',[_vm._v("\n                        "+_vm._s(_vm.$t(value.headline))+"\n                    ")])])],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }